<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>


    <vx-card ref="filterCard" title="Filters" class="user-list-filters mb-8">
      <div class="vx-row">
        <div class="vx-col w-full mb-3">
          <div class="float-right">
            <label class="text-sm opacity-75">PRIVACY</label>
            <vs-switch v-model="privacy" />
          </div>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Hospital Code</label>
          <v-select
            :options="hospitalCodeOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="hospitalCode"
            class="mb-4 md:mb-0"
          />
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Date</label>
          <v-select
            :options="dateOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="typeDate"
            class="mb-4 md:mb-0"
          />
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
          <small class="date-label">Start Date</small>
          <datepicker
            :language="$vs.rtl ? langHe : langEn"
            name="start-date"
            v-model="startDate"
            :disabled="typeDate.value != 'custom'"
            :disabledDates="disabledStartDatesFrom"
            :format="'dd/MM/yyyy'"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
          <small class="date-label">End Date</small>
          <datepicker
            :language="$vs.rtl ? langHe : langEn"
            :disabled="typeDate.value != 'custom'"
            :disabledDates="disabledEndDatesTo"
            name="end-date"
            v-model="endDate"
            :format="'dd/MM/yyyy'"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full">
          <!-- <small class="date-label"></small> -->
          <vs-button
            icon-pack="feather"
            icon="icon-edit"
            class="mt-6"
            @click="submitFilter(typeDate.value)"
          >Submit</vs-button>
        </div>
      </div>
    </vx-card>

    <div class="vx-row">
      <!-- COLUMN CHART -->
      <div class="vx-col md:w-1/2 w-full mb-base">
        <vx-card v-if="privacy" style="height:400px"></vx-card>
        <vx-card v-else title="Average Revenue by Physician">
          <!-- CARD ACTION -->
          <template slot="actions">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <small class="flex cursor-pointer">
                <span>{{filterAvgRevenueByPhysican}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1" />
              </small>
              <vs-dropdown-menu class="w-32">
                <vs-dropdown-item @click="getAvgRevenueByPhysican('last30Days')">Last 30 days</vs-dropdown-item>
                <vs-dropdown-item @click="getAvgRevenueByPhysican('thisMonth')">This month</vs-dropdown-item>
                <vs-dropdown-item @click="getAvgRevenueByPhysican('thisQuarter')">This quarter</vs-dropdown-item>
                <vs-dropdown-item @click="getAvgRevenueByPhysican('thisYear')">This year</vs-dropdown-item>
                <vs-dropdown-item @click="getAvgRevenueByPhysican('lastMonth')">Last month</vs-dropdown-item>
                <vs-dropdown-item @click="getAvgRevenueByPhysican('lastQuarter')">Last quarter</vs-dropdown-item>
                <vs-dropdown-item @click="getAvgRevenueByPhysican('lastYear')">Last year</vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>

          <vue-apex-charts
            v-if="avgRevenueByPhysican"
            type="line"
            height="350"
            :options="avgRevenueByPhysican.chartOptions"
            :series="avgRevenueByPhysican.series"
          ></vue-apex-charts>
        </vx-card>
      </div>

      <div class="vx-col md:w-1/2 w-full mb-base">
        <vx-card v-if="privacy" style="height:400px"></vx-card>
        <vx-card v-else title="Patient Encounter Breakdown">
          <!-- CARD ACTION -->
          <template slot="actions">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <small class="flex cursor-pointer">
                <span>{{filterPatientEncouterBreakdown}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1" />
              </small>
              <vs-dropdown-menu class="w-32">
                <vs-dropdown-item @click="getPatientEncouterBreakdown('last30Days')">Last 30 days</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdown('thisMonth')">This month</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdown('thisQuarter')">This quarter</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdown('thisYear')">This year</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdown('lastMonth')">Last month</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdown('lastQuarter')">Last quarter</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdown('lastYear')">Last year</vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>

          <vue-apex-charts
            v-if="patientEncouterBreakdown"
            type="bar"
            height="350"
            :options="patientEncouterBreakdown.chartOptions"
            :series="patientEncouterBreakdown.series"
          ></vue-apex-charts>
        </vx-card>
      </div>

      <div class="vx-col md:w-1/2 w-full mb-base">
        <vx-card v-if="privacy" style="height:400px"></vx-card>
        <vx-card v-else title="Patient Encounter Breakdown by days">
          <!-- CARD ACTION -->
          <template slot="actions">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <small class="flex cursor-pointer">
                <span>{{filterPatientEncouterBreakdownByDays}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1" />
              </small>
              <vs-dropdown-menu class="w-32">
                <vs-dropdown-item
                  @click="getPatientEncouterBreakdownByDays('last30Days')"
                >Last 30 days</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdownByDays('thisMonth')">This month</vs-dropdown-item>
                <vs-dropdown-item
                  @click="getPatientEncouterBreakdownByDays('thisQuarter')"
                >This quarter</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdownByDays('thisYear')">This year</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdownByDays('lastMonth')">Last month</vs-dropdown-item>
                <vs-dropdown-item
                  @click="getPatientEncouterBreakdownByDays('lastQuarter')"
                >Last quarter</vs-dropdown-item>
                <vs-dropdown-item @click="getPatientEncouterBreakdownByDays('lastYear')">Last year</vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>

          <vue-apex-charts
            v-if="patientEncouterBreakdownByDays"
            type="bar"
            height="350"
            :options="patientEncouterBreakdownByDays.chartOptions"
            :series="patientEncouterBreakdownByDays.series"
          ></vue-apex-charts>
        </vx-card>
      </div>

      <div class="vx-col md:w-1/2 w-full mb-base">
        <vx-card v-if="privacy" style="height:400px"></vx-card>
        <vx-card v-else title="Revenue by Clinic">
          <!-- CARD ACTION -->
          <!-- <template slot="actions">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <small class="flex cursor-pointer">
                <span>{{filterRevenueByClinic}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1" />
              </small>
              <vs-dropdown-menu class="w-32">
                <vs-dropdown-item @click="getRevenueByClinic('last30Days')">Last 30 days</vs-dropdown-item>
                <vs-dropdown-item @click="getRevenueByClinic('thisMonth')">This month</vs-dropdown-item>
                <vs-dropdown-item @click="getRevenueByClinic('thisQuarter')">This quarter</vs-dropdown-item>
                <vs-dropdown-item @click="getRevenueByClinic('thisYear')">This year</vs-dropdown-item>
                <vs-dropdown-item @click="getRevenueByClinic('lastMonth')">Last month</vs-dropdown-item>
                <vs-dropdown-item @click="getRevenueByClinic('lastQuarter')">Last quarter</vs-dropdown-item>
                <vs-dropdown-item @click="getRevenueByClinic('lastYear')">Last year</vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>-->

          <v-select
            multiple
            :closeOnSelect="false"
            v-model="hospitalCodeRevenueByClinic"
            :options="hospitalCodeOptions"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="mb-2"
          />

          <vs-dropdown vs-trigger-click class="cursor-pointer mb-2">
            <small class="flex cursor-pointer">
              <span>{{filterRevenueByClinic}}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1" />
            </small>
            <vs-dropdown-menu class="w-32">
              <vs-dropdown-item @click="getRevenueByClinic('last30Days')">Last 30 days</vs-dropdown-item>
              <vs-dropdown-item @click="getRevenueByClinic('thisMonth')">This month</vs-dropdown-item>
              <vs-dropdown-item @click="getRevenueByClinic('thisQuarter')">This quarter</vs-dropdown-item>
              <vs-dropdown-item @click="getRevenueByClinic('thisYear')">This year</vs-dropdown-item>
              <vs-dropdown-item @click="getRevenueByClinic('lastMonth')">Last month</vs-dropdown-item>
              <vs-dropdown-item @click="getRevenueByClinic('lastQuarter')">Last quarter</vs-dropdown-item>
              <vs-dropdown-item @click="getRevenueByClinic('lastYear')">Last year</vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <vue-apex-charts
            v-if="revenueByClinic"
            type="pie"
            height="350"
            :options="revenueByClinic.chartOptions"
            :series="revenueByClinic.series"
          ></vue-apex-charts>
        </vx-card>
      </div>

      <div class="vx-col md:w-1/2 w-full mb-base">
        <vx-card v-if="privacy" style="height:400px"></vx-card>
        <vx-card v-else title="Revenue OP vs CHC">
          <!-- CARD ACTION -->
          <template slot="actions">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <small class="flex cursor-pointer">
                <span>{{filterRevenueOPvsCHC}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1" />
              </small>
              <vs-dropdown-menu class="w-32">
                <vs-dropdown-item @click="getRevenueOPvsCHC('last30Days')">Last 30 days</vs-dropdown-item>
                <vs-dropdown-item @click="getRevenueOPvsCHC('thisMonth')">This month</vs-dropdown-item>
                <vs-dropdown-item @click="getRevenueOPvsCHC('thisQuarter')">This quarter</vs-dropdown-item>
                <vs-dropdown-item @click="getRevenueOPvsCHC('thisYear')">This year</vs-dropdown-item>
                <vs-dropdown-item @click="getRevenueOPvsCHC('lastMonth')">Last month</vs-dropdown-item>
                <vs-dropdown-item @click="getRevenueOPvsCHC('lastQuarter')">Last quarter</vs-dropdown-item>
                <vs-dropdown-item @click="getRevenueOPvsCHC('lastYear')">Last year</vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>

          <vue-apex-charts
            v-if="revenueOPvsCHC"
            type="line"
            height="350"
            :options="revenueOPvsCHC.chartOptions"
            :series="revenueOPvsCHC.series"
          ></vue-apex-charts>
        </vx-card>
      </div>

      <div class="vx-col md:w-1/2 w-full mb-base">
        <vx-card v-if="privacy" style="height:400px"></vx-card>
        <vx-card v-else title="Patient Payer Breakdown By Speciliaty">
          <!-- CARD ACTION -->
          <template slot="actions">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <small class="flex cursor-pointer">
                <span>{{filterPatientPayerBreakdownBySpeciliaty}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="ml-1" />
              </small>
              <vs-dropdown-menu class="w-32">
                <vs-dropdown-item
                  @click="getPatientPayerBreakdownBySpeciliaty('last30Days')"
                >Last 30 days</vs-dropdown-item>
                <vs-dropdown-item
                  @click="getPatientPayerBreakdownBySpeciliaty('thisMonth')"
                >This month</vs-dropdown-item>
                <vs-dropdown-item
                  @click="getPatientPayerBreakdownBySpeciliaty('thisQuarter')"
                >This quarter</vs-dropdown-item>
                <vs-dropdown-item
                  @click="getPatientPayerBreakdownBySpeciliaty('thisYear')"
                >This year</vs-dropdown-item>
                <vs-dropdown-item
                  @click="getPatientPayerBreakdownBySpeciliaty('lastMonth')"
                >Last month</vs-dropdown-item>
                <vs-dropdown-item
                  @click="getPatientPayerBreakdownBySpeciliaty('lastQuarter')"
                >Last quarter</vs-dropdown-item>
                <vs-dropdown-item
                  @click="getPatientPayerBreakdownBySpeciliaty('lastYear')"
                >Last year</vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>

          <vue-apex-charts
            v-if="patientPayerBreakdownBySpeciliaty"
            type="bar"
            height="350"
            :options="patientPayerBreakdownBySpeciliaty.chartOptions"
            :series="patientPayerBreakdownBySpeciliaty.series"
          ></vue-apex-charts>
        </vx-card>
      </div>

      <!-- <div class="vx-col md:w-1/2 w-full mb-base">
        <vx-card title="Column Chart">
          <vue-apex-charts
            type="bar"
            height="350"
            :options="Chart1.chartOptions"
            :series="Chart1.series"
          ></vue-apex-charts>
        </vx-card>
      </div>-->

      <!-- COLUMN CHART -->
      <!-- <div class="vx-col md:w-1/2 w-full mb-base">
        <vx-card title="Column Chart">
          <vue-apex-charts
            type="bar"
            height="350"
            :options="Chart2.chartOptions"
            :series="Chart2.series"
          ></vue-apex-charts>
        </vx-card>
      </div>-->

      <!-- COLUMN CHART -->
      <!-- <div class="vx-col md:w-1/2 w-full mb-base">
        <vx-card title="Column Chart">
          <vue-apex-charts
            type="line"
            height="350"
            :options="Chart3.chartOptions"
            :series="Chart3.series"
          ></vue-apex-charts>
        </vx-card>
      </div>-->

      <!-- PIE CHART -->
      <!-- <div class="vx-col md:w-1/2 w-full mb-base">
        <vx-card title="Column Chart">
          <vue-apex-charts
            type="pie"
            height="350"
            :options="Chart4.chartOptions"
            :series="Chart4.series"
          ></vue-apex-charts>
        </vx-card>
      </div>-->

      <!-- <div class="vx-col md:w-1/2 w-full mb-base">
        <vx-card title="Default">
          <vs-table :data="myData">
            <template slot="thead">
              <vs-th></vs-th>
              <vs-th>Actual</vs-th>
              <vs-th>Budget</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].name">{{data[indextr].name}}</vs-td>
                <vs-td :data="data[indextr].actual">{{data[indextr].actual}}</vs-td>
                <vs-td :data="data[indextr].budget">{{data[indextr].budget}}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>-->

      <!-- COLUMN CHART -->
      <!-- <div class="vx-col md:w-1/2 w-full mb-base">
        <vx-card title="Column Chart">
          <vue-apex-charts
            type="bar"
            height="350"
            :options="Chart5.chartOptions"
            :series="Chart5.series"
          ></vue-apex-charts>
        </vx-card>
      </div>-->

      <!-- COLUMN CHART -->
      <!-- <div class="vx-col w-full mb-base">
        <vx-card>
          <div class="vx-row mt-6">
            <div
              class="vx-col md:w-1/4 w-full border-solid d-theme-border-grey-light border-t-0 border-b-0 border-l-0 mt-6"
            >
              <h6 class="font-bold mb-3">Total Revenue</h6>
              <div class="flex justify-between">
                <div class="flex flex-col">
                  <h4>Month: $XXM</h4>
                  <span class="flex -ml-1">
                    <feather-icon
                      icon="ArrowUpIcon"
                      svgClasses="text-success stroke-current h-4 w-4 ml-1"
                    ></feather-icon>
                    <span class="ml-1 text-success">+8%</span>
                  </span>
                </div>
                <div class="flex flex-col text-right">
                  <h4>YTD: US$</h4>
                  <span class="flex -mr-1">
                    <feather-icon
                      icon="ArrowDownIcon"
                      svgClasses="text-danger stroke-current h-4 w-4  ml-1"
                    ></feather-icon>
                    <span class="ml-1 text-danger">+5%</span>
                  </span>
                </div>
              </div>
            </div>


            <div class="vx-col md:w-3/4 w-full">
              <div class="vx-row">
                <div class="vx-col md:w-1/3 w-full mt-6">
                  <h6 class="font-bold mb-3">Total Revenue</h6>
                  <div class="flex justify-between">
                    <div class="flex flex-col">
                      <h4>Month: $XXM</h4>
                      <span class="flex -ml-1">
                        <feather-icon
                          icon="ArrowUpIcon"
                          svgClasses="text-success stroke-current h-4 w-4 ml-1"
                        ></feather-icon>
                        <span class="ml-1 text-success">+8%</span>
                      </span>
                    </div>
                    <div class="flex flex-col text-right">
                      <h4>YTD: US$</h4>
                      <span class="flex -mr-1">
                        <feather-icon
                          icon="ArrowDownIcon"
                          svgClasses="text-danger stroke-current h-4 w-4  ml-1"
                        ></feather-icon>
                        <span class="ml-1 text-danger">+5%</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-col md:w-1/3 w-full mt-6">
                  <h6 class="font-bold mb-3">Total Revenue</h6>
                  <div class="flex justify-between">
                    <div class="flex flex-col">
                      <h4>Month: $XXM</h4>
                      <span class="flex -ml-1">
                        <feather-icon
                          icon="ArrowUpIcon"
                          svgClasses="text-success stroke-current h-4 w-4 ml-1"
                        ></feather-icon>
                        <span class="ml-1 text-success">+8%</span>
                      </span>
                    </div>
                    <div class="flex flex-col text-right">
                      <h4>YTD: US$</h4>
                      <span class="flex -mr-1">
                        <feather-icon
                          icon="ArrowDownIcon"
                          svgClasses="text-danger stroke-current h-4 w-4  ml-1"
                        ></feather-icon>
                        <span class="ml-1 text-danger">+5%</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-col md:w-1/3 w-full mt-6">
                  <h6 class="font-bold mb-3">Total Revenue</h6>
                  <div class="flex justify-between">
                    <div class="flex flex-col">
                      <h4>Month: $XXM</h4>
                      <span class="flex -ml-1">
                        <feather-icon
                          icon="ArrowUpIcon"
                          svgClasses="text-success stroke-current h-4 w-4 ml-1"
                        ></feather-icon>
                        <span class="ml-1 text-success">+8%</span>
                      </span>
                    </div>
                    <div class="flex flex-col text-right">
                      <h4>YTD: US$</h4>
                      <span class="flex -mr-1">
                        <feather-icon
                          icon="ArrowDownIcon"
                          svgClasses="text-danger stroke-current h-4 w-4  ml-1"
                        ></feather-icon>
                        <span class="ml-1 text-danger">+5%</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-col md:w-1/3 w-full mt-6">
                  <h6 class="font-bold mb-3">Total Revenue</h6>
                  <div class="flex justify-between">
                    <div class="flex flex-col">
                      <h4>Month: $XXM</h4>
                      <span class="flex -ml-1">
                        <feather-icon
                          icon="ArrowUpIcon"
                          svgClasses="text-success stroke-current h-4 w-4 ml-1"
                        ></feather-icon>
                        <span class="ml-1 text-success">+8%</span>
                      </span>
                    </div>
                    <div class="flex flex-col text-right">
                      <h4>YTD: US$</h4>
                      <span class="flex -mr-1">
                        <feather-icon
                          icon="ArrowDownIcon"
                          svgClasses="text-danger stroke-current h-4 w-4  ml-1"
                        ></feather-icon>
                        <span class="ml-1 text-danger">+5%</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-col md:w-1/3 w-full mt-6">
                  <h6 class="font-bold mb-3">Total Revenue</h6>
                  <div class="flex justify-between">
                    <div class="flex flex-col">
                      <h4>Month: $XXM</h4>
                      <span class="flex -ml-1">
                        <feather-icon
                          icon="ArrowUpIcon"
                          svgClasses="text-success stroke-current h-4 w-4 ml-1"
                        ></feather-icon>
                        <span class="ml-1 text-success">+8%</span>
                      </span>
                    </div>
                    <div class="flex flex-col text-right">
                      <h4>YTD: US$</h4>
                      <span class="flex -mr-1">
                        <feather-icon
                          icon="ArrowDownIcon"
                          svgClasses="text-danger stroke-current h-4 w-4  ml-1"
                        ></feather-icon>
                        <span class="ml-1 text-danger">+5%</span>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="vx-col md:w-1/3 w-full mt-6">
                  <h6 class="font-bold mb-3">Imaging Utilization Rate</h6>
                  <vue-apex-charts
                    type="radialBar"
                    height="200"
                    :options="Chart6.chartOptions"
                    :series="Chart6.series"
                  />
                </div>

                <div class="vx-col md:w-1/3 w-full mt-6">
                  <div>
                    <h6 class="font-bold mb-3">Total Patients</h6>
                    <div class="flex justify-between">
                      <div class="flex flex-col">
                        <h5>10,230</h5>
                        <span class="flex -ml-1">
                          <feather-icon
                            icon="ArrowUpIcon"
                            svgClasses="text-success stroke-current h-4 w-4 ml-1"
                          ></feather-icon>
                          <span class="ml-1 text-success">+8%</span>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="mt-6">
                    <h6 class="font-bold mb-3">Number of Employees</h6>
                    <div class="flex justify-between">
                      <div class="flex flex-col">
                        <h5>10,230</h5>
                        <span class="flex -ml-1">
                          <feather-icon
                            icon="ArrowUpIcon"
                            svgClasses="text-success stroke-current h-4 w-4 ml-1"
                          ></feather-icon>
                          <span class="ml-1 text-success">+8%</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="vx-col md:w-1/3 w-full mt-6">
                  <div>
                    <h6 class="font-bold mb-3">Total Patients</h6>
                    <div class="flex justify-between">
                      <div class="flex flex-col">
                        <h5>10,230</h5>
                        <span class="flex -ml-1">
                          <feather-icon
                            icon="ArrowUpIcon"
                            svgClasses="text-success stroke-current h-4 w-4 ml-1"
                          ></feather-icon>
                          <span class="ml-1 text-success">+8%</span>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="mt-6">
                    <h6 class="font-bold mb-3">Number of Employees</h6>
                    <div class="flex justify-between">
                      <div class="flex flex-col">
                        <h5>10,230</h5>
                        <span class="flex -ml-1">
                          <feather-icon
                            icon="ArrowUpIcon"
                            svgClasses="text-success stroke-current h-4 w-4 ml-1"
                          ></feather-icon>
                          <span class="ml-1 text-success">+8%</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="vx-col md:w-1/3 w-full mt-6">
                  <vs-table :data="equipments">
                    <template slot="thead">
                      <vs-th></vs-th>
                      <vs-th>Growth</vs-th>
                    </template>

                    <template slot-scope="{data}">
                      <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="data[indextr].name">{{data[indextr].name}}</vs-td>
                        <vs-td :data="data[indextr].growth">{{data[indextr].growth}}</vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>-->
    </div>

    <vx-card ref="filterCard" title="Filters Revenue 2 Years" class="user-list-filters mb-8">
      <div class="vx-row">
        <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Hospital Code</label>
          <v-select
            :options="hospitalCodeOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="hospitalCode2"
            class="mb-4 md:mb-0"
          />
        </div>
        <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
          <small class="date-label">Last Start Time</small>
          <datepicker
            v-model="lastStartTime"
            :disabledDates="disabledStartDatesFrom"
            :minimumView="'month'"
            :maximumView="'year'"
            :format="'MM/yyyy'"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
          <small class="date-label">Last End Time</small>
          <datepicker
            v-model="lastEndTime"
            :disabledDates="disabledStartDatesFrom"
            :minimumView="'month'"
            :maximumView="'year'"
            :format="'MM/yyyy'"
          ></datepicker>
        </div>

        <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
          <small class="date-label">This Start Time</small>
          <datepicker
            v-model="thisStartTime"
            :disabledDates="disabledStartDatesFrom"
            :minimumView="'month'"
            :maximumView="'year'"
            :format="'MM/yyyy'"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
          <small class="date-label">This End Time</small>
          <datepicker
            v-model="thisEndTime"
            :disabledDates="disabledStartDatesFrom"
            :minimumView="'month'"
            :maximumView="'year'"
            :format="'MM/yyyy'"
          ></datepicker>
        </div>

        <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
          <!-- <small class="date-label"></small> -->
          <vs-button
            icon-pack="feather"
            icon="icon-edit"
            class="mt-6"
            @click="submitFiltersRevenue2Years"
          >Submit</vs-button>
        </div>
      </div>
    </vx-card>

    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full mb-base">
        <vx-card v-if="privacy" style="height:400px"></vx-card>
        <vx-card v-else title="Gross Revenue 2 Years">
          <!-- CARD ACTION -->

          <vue-apex-charts
            v-if="grossRevenue2years"
            type="line"
            height="350"
            :options="grossRevenue2years.chartOptions"
            :series="grossRevenue2years.series"
          ></vue-apex-charts>
        </vx-card>
      </div>
    </div>

    <vx-card ref="filterCard" title="Filters Financial Overview" class="user-list-filters mb-8">
      <div class="vx-row">
        <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Category</label>
          <v-select
            :options="category"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="filterCategory"
            class="mb-4 md:mb-0"
          />
        </div>

        <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Service Type</label>
          <v-select
            :options="serviceType"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="filterServiceType"
            class="mb-4 md:mb-0"
          />
        </div>

        <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
          <!-- <small class="date-label"></small> -->
          <vs-button
            icon-pack="feather"
            icon="icon-edit"
            class="mt-6"
            @click="submitFinancialOverview"
          >Submit</vs-button>
        </div>
      </div>
    </vx-card>

    <div class="vx-row">
      <div
        v-for="(item, index) in filteredFinancialOverview"
        :key="index"
        class="vx-col w-full mt-6"
        v-if="item.data.length"
      >
        <vx-card :title="item.name" >
          <div class="vx-row flex">
            <div
              class="vx-col w-full md:w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 flex flex-col mb-5"
              :key="`data-${k}`"
              v-for="(i, k) in item.data"
            >
              <!-- <h5 class="font-bold" v-html="`${item.data[0].header} : ${item.data[0].valueUnit}`"></h5> -->
              <small v-html="i.category"></small>
              <h5 class="font-bold" v-html="`${i.header} : ${i.value} EUR`"></h5>
              <span class="flex -ml-1">
                <feather-icon
                  v-if="i.percent >= 0"
                  icon="ArrowUpIcon"
                  svgClasses="text-success stroke-current h-4 w-4 ml-1"
                ></feather-icon>
                <feather-icon
                  v-else
                  icon="ArrowDownIcon"
                  svgClasses="text-danger stroke-current h-4 w-4  ml-1"
                ></feather-icon>
                <span
                  class="ml-1"
                  :class="i.percent >= 0 ? 'text-success' : 'text-danger'"
                  v-html="i.percent >= 0 ? `+${i.percent}%` : `${i.percent}%`"
                ></span>
              </span>
            </div>
          </div>
        </vx-card>
      </div>
    </div>


  </div>


</template>

<script>
import { mapActions, mapState } from 'vuex'

import VueApexCharts from 'vue-apexcharts'

import vSelect from 'vue-select'

import Datepicker from 'vuejs-datepicker'
import { en, he } from 'vuejs-datepicker/src/locale'

import updateToken from '@/middlewares/updateToken';

const themeColors = ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E']

//import apexChatData from '@/views/charts-and-maps/charts/apex-charts/apexChartData.js'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'


export default {
  components: {
    VueApexCharts,
    vSelect,
    Datepicker,
    ChangeTimeDurationDropdown
  },
  data () {
    return {
      //apexChatData,
      accounts: null,
      Chart1: {
        series: [
          {
            name: 'Prior Year',
            type: 'column',
            data: [8, 6, 6, 6]
          },
          {
            name: 'Current Year',
            type: 'column',
            data: [10, 9, 11, 9]
          },
          {
            name: 'Target Rate (Benchmark to Industry)',
            type: 'line',
            data: [4.8, 4.8, 4.8, 4.8]
          }
        ],
        chartOptions: {
          colors: themeColors,
          chart: {
            height: 350,
            type: 'line',
            stacked: false
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [1, 1, 4]
          },

          xaxis: {
            categories: ['Q1', 'Q2', 'Q3', 'Q4']
          },
          yaxis: [
            {
              axisTicks: {
                show: true
              },
              axisBorder: {
                show: true,
                color: '#008FFB'
              },
              labels: {
                style: {
                  colors: '#008FFB'
                }
              },

              tooltip: {
                enabled: true
              },
              max: 12
            },
            {
              seriesName: 'Income',
              show: false,
              axisTicks: {
                show: true
              },
              axisBorder: {
                show: true,
                color: '#00E396'
              },
              labels: {
                style: {
                  colors: '#00E396'
                }
              },
              max: 12
            },
            {
              seriesName: 'Revenue',
              show: false,
              axisTicks: {
                show: true
              },
              axisBorder: {
                show: true,
                color: '#FEB019'
              },
              labels: {
                style: {
                  colors: '#FEB019'
                }
              },
              max: 12
            }
          ],
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
              offsetY: 30,
              offsetX: 60
            }
          },
          legend: {
            horizontalAlign: 'left',
            offsetX: 40
          }
        }
      },

      Chart2: {
        series: [
          {
            name: 'Corporate',
            data: [500, 600, 700, 700, 500, 700, 700, 700, 700, 700, 700, 700]
          },
          {
            name: 'Individual',
            data: [200, 200, 200, 200, 200, 150, 200, 200, 200, 150, 200, 200]
          }
        ],
        chartOptions: {
          colors: themeColors,
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                position: 'top'
              }
            }
          },
          title: {
            text: 'Patient Encounters',
            align: 'left',
            offsetX: 110
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
          },
          xaxis: {
            categories: ['Q1', 'Q2', 'Q3', 'Q4']
          }
        }
      },

      Chart3: {
        series: [
          {
            name: 'Avg Revenue Blog',
            type: 'column',
            data: [2, 3, 5]
          },
          {
            name: 'Nb Physicain (RHS)',
            type: 'line',
            data: [2, 4.3, 15.2]
          }
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            stacked: true
          },
          stroke: {
            curve: 'smooth',
            width: 2
          },
          title: {
            text: 'Traffic Sources'
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: [1]
          },

          labels: ['Specialty 1', 'Specialty 2', 'Specialty 3'],

          yaxis: [
            {
              max: 10
            },
            {
              opposite: true
            }
          ]
        }
      },

      Chart4: {
        series: [58, 30, 13, 43, 36],
        chartOptions: {
          labels: ['Clinic 1', 'Clinic 2', 'Clinic 3', 'Clinic 4', 'Clinic 5'],
          colors: themeColors,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }
          ],
          title: {
            text: 'Revenue Split',
            align: 'left',
            offsetX: 110
          }
        }
      },

      myData: [
        {
          name: 'OP Volume (‘000)',
          actual: '344',
          budget: '566'
        },
        {
          name: 'Revenue per OP (US$)',
          actual: '355',
          budget: '400'
        },
        {
          name: 'Revenue per IP (US$)',
          actual: '404',
          budget: '425'
        },
        {
          name: 'Occupancy (%)',
          actual: '77%',
          budget: '87%'
        },
        {
          name: 'ALOS (days)',
          actual: '4',
          budget: '4.7'
        }
      ],

      Chart5: {
        series: [
          {
            name: 'Social',
            data: [10000, 12000, 12500]
          },
          {
            name: 'Private',
            data: [2000, 7000, 1500]
          },
          {
            name: 'Out-of-pocket',
            data: [1000, 2000, 1000]
          }
        ],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }
          ],

          plotOptions: {
            bar: {
              horizontal: false
            }
          },
          xaxis: {
            categories: ['Specialty 1', 'Specialty 2', 'Specialty 3']
          },
          yaxis: [
            {
              max: 25000
            },
            {
              opposite: true,
              max: 25000,
              show: false
            },
            {
              opposite: true,
              max: 25000,
              show: false
            }
          ],
          legend: {
            position: 'right',
            offsetY: 40
          },
          fill: {
            opacity: 1
          }
        }
      },

      Chart6: {
        series: [76],
        chartOptions: {
          chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
              enabled: true
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              track: {
                background: '#e7e7e7',
                strokeWidth: '97%',
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: '#999',
                  opacity: 1,
                  blur: 2
                }
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  offsetY: -2,
                  fontSize: '22px'
                }
              }
            }
          },
          grid: {
            padding: {
              top: -10
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              shadeIntensity: 0.4,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 53, 91]
            }
          },
          labels: ['Average Results']
        }
      },

      equipments: [
        {
          name: 'CT',
          growth: '4,630'
        },
        {
          name: 'MR',
          growth: '241.3'
        },
        {
          name: 'US',
          growth: '31'
        },
        {
          name: 'XRAY',
          growth: '440'
        }
      ],

      privacy: false,

      hospitalCode: 'CLINIC1',
      hospitalCode2: 'CLINIC2',
      hospitalCodeOptions: [
'CLINIC1',
'CLINIC2',
'CLINIC3',
'CLINIC4',
'CLINIC6',
'CLINIC7',
'CLINIC8',
'CLINIC9',
'WSGK1',
'ORGLOGIX1'

      ],
      startDate: new Date(),
      endDate: new Date(),
      langHe: he,
      langEn: en,

      specialitys: [
        'Gynecology',
        'Multi',
        'Vaccinnation',
        'Obstetrics',
        'ENT',
        'Ophthalmology',
        'Oncology',
        'Neonatology',
        'Pediatrics',
        'Neuro Sciences'
      ],

      endDay: 0,
      endMonth: 0,
      endYear: 0,

      startDay: 0,
      startMonth: 0,
      startYear: 0,

      lastEndTime: new Date(),
      lastStartTime: new Date(),
      thisEndTime: new Date(),
      thisStartTime: new Date(),

      typeDate: { label: 'Last 30 days', value: 'last30Days' },
      dateOptions: [
        { label: 'Last 30 days', value: 'last30Days' },
        { label: 'This month', value: 'thisMonth' },
        { label: 'This quarter', value: 'thisQuarter' },
        { label: 'This year', value: 'thisYear' },
        { label: 'Last month', value: 'lastMonth' },
        { label: 'Last quarter', value: 'lastQuarter' },
        { label: 'Last year', value: 'lastYear' },
        { label: 'Custom', value: 'custom' }
      ],

      filterAvgRevenueByPhysican: 'Last 30 days',
      filterPatientEncouterBreakdown: 'Last 30 days',
      filterPatientEncouterBreakdownByDays: 'Last 30 days',
      filterRevenueByClinic: 'Last 30 days',
      filterRevenueOPvsCHC: 'Last 30 days',
      filterPatientPayerBreakdownBySpeciliaty: 'Last 30 days',

      hospitalCodeRevenueByClinic: ['CLINIC1', 'CLINIC2', 'CLINIC3', 'CLINIC4'],

      category: ['All', 'Cost', 'Revenue', 'Volume', 'Doctor', 'Nurse', 'EBITDA'],
      serviceType: ['All', 'Lab', 'Full', 'MRI'],
      filterCategory: 'All',
      filterServiceType: 'All',
      filteredFinancialOverview: []
    }
  },
  computed: {
    ...mapState('chart', [
      'avgRevenueByPhysican',
      'grossRevenue2years',
      'patientEncouterBreakdown',
      'patientEncouterBreakdownByDays',
      'revenueByClinic',
      'revenueOPvsCHC',
      'financialOverview',
      'patientPayerBreakdownBySpeciliaty'
    ]),

    disabledStartDatesFrom () {
      return { from: new Date() }
    },

    disabledEndDatesTo () {
      return { to: new Date(this.startDate) }
    },

    disabledThisTimeTo () {
      return { to: new Date(this.lastTime) }
    }


  },
  async mounted () {
    console.log()
    console.log(updateToken())
    await this.submitFilter(this.typeDate.value)
    if (this.financialOverview.length) this.submitFinancialOverview()

    this.submitFiltersRevenue2Years()
  },
  watch: {
    privacy () {
      if (this.privacy) {
        this.$vs.notify({
          title: 'Privacy',
          text: 'See your financial info by turning privacy off.',
          color: 'success',
          time: 8000,
          position: 'bottom-center'
        })
      }
    }
  },
  created () {






  },
  methods: {
    ...mapActions('chart', [
      'fetchAvgRevenueByPhysican',
      'fetchPatientEncouterBreakdown',
      'fetchGrossRevenue2years',
      'fetchPatientEncouterBreakdownByDays',
      'fetchRevenueByClinic',
      'fetchRevenueOPvsCHC',
      'fetchFinancialOverview',
      'fetchPatientPayerBreakdownBySpeciliaty'
    ]),

    getDatesRange (type) {
      const _this = this

      function last30Days () {
        const startDate = _this.$moment().subtract(30, 'days')
        const endDate = _this.$moment()

        return { startDate, endDate }
      }

      function thisMonth () {
        const startDate = _this.$moment().startOf('month')
        const endDate = _this.$moment().endOf('month')

        return { startDate, endDate }
      }

      function thisQuarter () {
        const startDate = _this
          .$moment()
          .quarter(_this.$moment().quarter())
          .startOf('quarter')
        const endDate = _this
          .$moment()
          .quarter(_this.$moment().quarter())
          .endOf('quarter')

        return { startDate, endDate }
      }

      function thisYear () {
        const startDate = _this.$moment().startOf('year')
        const endDate = _this.$moment().endOf('year')

        return { startDate, endDate }
      }

      function lastMonth () {
        const startDate = _this
          .$moment()
          .subtract(1, 'months')
          .startOf('month')
        const endDate = _this.$moment(startDate).endOf('month')

        return { startDate, endDate }
      }

      function lastQuarter () {
        const startDate = _this
          .$moment()
          .subtract(1, 'quarter')
          .startOf('quarter')
        const endDate = _this
          .$moment()
          .subtract(1, 'quarter')
          .endOf('quarter')

        return { startDate, endDate }
      }

      function lastYear () {
        const startDate = _this
          .$moment()
          .subtract(1, 'year')
          .startOf('year')
        const endDate = _this
          .$moment()
          .subtract(1, 'year')
          .endOf('year')

        return { startDate, endDate }
      }

      function custom () {
        return { startDate: _this.startDate, endDate: _this.endDate }
      }

      const dates = {
        last30Days,
        thisMonth,
        thisQuarter,
        thisYear,
        lastMonth,
        lastQuarter,
        lastYear,
        custom
      }

      return dates[type]()
    },
    async submitFilter (type) {
      const dateRange = this.getDatesRange(type)
      const startDay = this.$moment(dateRange.startDate).date()
      const startMonth = this.$moment(dateRange.startDate).month() + 1
      const startYear = this.$moment(dateRange.startDate).year()

      const endDay = this.$moment(dateRange.endDate).date()
      const endMonth = this.$moment(dateRange.endDate).month() + 1
      const endYear = this.$moment(dateRange.endDate).year()

      const p1 = this.fetchAvgRevenueByPhysican({
        endDay,
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        specialitys: this.specialitys,
        startDay,
        startMonth,
        startYear
      }).catch(err => {
        console.error(err)
      })

      const p2 = this.fetchPatientEncouterBreakdown({
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        startMonth,
        startYear
      }).catch(err => {
        console.error(err)
      })

      const p3 = this.fetchPatientEncouterBreakdownByDays({
        endDay,
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        startDay,
        startMonth,
        startYear
      }).catch(err => {
        console.error(err)
      })

      const p4 = this.fetchRevenueByClinic({
        endDay,
        endMonth,
        endYear,
        hospitalCodes: this.hospitalCodeRevenueByClinic,
        startDay,
        startMonth,
        startYear
      })
        // .then(res => {
        //   console.log(res)
        // })
        .catch(err => {
          console.error(err)
        })

      const p5 = this.fetchRevenueOPvsCHC({
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        startMonth,
        startYear
      })
        // .then(res => {
        //   console.log(res)
        // })
        .catch(err => {
          console.error(err)
        })

      const p6 = this.fetchFinancialOverview({
        endDay,
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        startDay,
        startMonth,
        startYear
      }).catch(err => {
        console.error(err)
      })

      const p7 = this.fetchPatientPayerBreakdownBySpeciliaty({
        endDay,
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        specialitys: this.specialitys,
        startDay,
        startMonth,
        startYear
      }).catch(err => {
        console.error(err)
      })

      await Promise.all([p1, p2, p3, p4, p5, p6, p7])
    },
    getAvgRevenueByPhysican (type) {
      this.filterAvgRevenueByPhysican = this.dateOptions.find(
        x => x.value === type
      ).label

      const dateRange = this.getDatesRange(type)
      const startDay = this.$moment(dateRange.startDate).date()
      const startMonth = this.$moment(dateRange.startDate).month() + 1
      const startYear = this.$moment(dateRange.startDate).year()

      const endDay = this.$moment(dateRange.endDate).date()
      const endMonth = this.$moment(dateRange.endDate).month() + 1
      const endYear = this.$moment(dateRange.endDate).year()

      const payload = {
        endDay,
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        specialitys: this.specialitys,
        startDay,
        startMonth,
        startYear
      }

      this.fetchAvgRevenueByPhysican(payload)
        // .then(res => {
        //   console.log(res)
        // })
        .catch(err => {
          console.error(err)
        })




    },
    getPatientEncouterBreakdown (type) {
      this.filterPatientEncouterBreakdown = this.dateOptions.find(
        x => x.value === type
      ).label

      const dateRange = this.getDatesRange(type)
      const startMonth = this.$moment(dateRange.startDate).month() + 1
      const startYear = this.$moment(dateRange.startDate).year()

      const endMonth = this.$moment(dateRange.endDate).month() + 1
      const endYear = this.$moment(dateRange.endDate).year()

      const payload = {
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        startMonth,
        startYear
      }

      this.fetchPatientEncouterBreakdown(payload)
        // .then(res => {
        //   console.log(res)
        // })
        .catch(err => {
          console.error(err)
        })
    },
    getPatientEncouterBreakdownByDays (type) {
      this.filterPatientEncouterBreakdownByDays = this.dateOptions.find(
        x => x.value === type
      ).label

      const dateRange = this.getDatesRange(type)
      const startDay = this.$moment(dateRange.startDate).date()
      const startMonth = this.$moment(dateRange.startDate).month() + 1
      const startYear = this.$moment(dateRange.startDate).year()

      const endDay = this.$moment(dateRange.endDate).date()
      const endMonth = this.$moment(dateRange.endDate).month() + 1
      const endYear = this.$moment(dateRange.endDate).year()

      this.fetchPatientEncouterBreakdownByDays({
        endDay,
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        startDay,
        startMonth,
        startYear
      })
        // .then(res => {
        //   console.log(res)
        // })
        .catch(err => {
          console.error(err)
        })
    },
    getRevenueByClinic (type) {
      this.filterRevenueByClinic = this.dateOptions.find(
        x => x.value === type
      ).label

      const dateRange = this.getDatesRange(type)
      const startDay = this.$moment(dateRange.startDate).date()
      const startMonth = this.$moment(dateRange.startDate).month() + 1
      const startYear = this.$moment(dateRange.startDate).year()

      const endDay = this.$moment(dateRange.endDate).date()
      const endMonth = this.$moment(dateRange.endDate).month() + 1
      const endYear = this.$moment(dateRange.endDate).year()

      this.fetchRevenueByClinic({
        endDay,
        endMonth,
        endYear,
        hospitalCodes: this.hospitalCodeRevenueByClinic,
        startDay,
        startMonth,
        startYear
      })
        // .then(res => {
        //   console.log(res)
        // })
        .catch(err => {
          console.error(err)
        })
    },
    getRevenueOPvsCHC (type) {
      this.filterRevenueOPvsCHC = this.dateOptions.find(
        x => x.value === type
      ).label

      const dateRange = this.getDatesRange(type)
      const startMonth = this.$moment(dateRange.startDate).month() + 1
      const startYear = this.$moment(dateRange.startDate).year()

      const endMonth = this.$moment(dateRange.endDate).month() + 1
      const endYear = this.$moment(dateRange.endDate).year()

      this.fetchRevenueOPvsCHC({
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        startMonth,
        startYear
      })
        // .then(res => {
        //   console.log(res)
        // })
        .catch(err => {
          console.error(err)
        })
    },
    getPatientPayerBreakdownBySpeciliaty (type) {
      this.filterPatientPayerBreakdownBySpeciliaty = this.dateOptions.find(
        x => x.value === type
      ).label

      const dateRange = this.getDatesRange(type)
      const startDay = this.$moment(dateRange.startDate).date()
      const startMonth = this.$moment(dateRange.startDate).month() + 1
      const startYear = this.$moment(dateRange.startDate).year()

      const endDay = this.$moment(dateRange.endDate).date()
      const endMonth = this.$moment(dateRange.endDate).month() + 1
      const endYear = this.$moment(dateRange.endDate).year()

      const payload = {
        endDay,
        endMonth,
        endYear,
        hospitalCode: this.hospitalCode,
        specialitys: this.specialitys,
        startDay,
        startMonth,
        startYear
      }

      this.fetchPatientPayerBreakdownBySpeciliaty(payload).catch(err => {
        console.error(err)
      })
    },

    submitFiltersRevenue2Years () {
      const lastStartMonth = this.$moment(this.lastStartTime).month() + 1
      const lastStartYear = this.$moment(this.lastStartTime).year()
      const lastEndMonth = this.$moment(this.lastEndTime).month() + 1
      const lastEndYear = this.$moment(this.lastEndTime).year()

      const thisStartMonth = this.$moment(this.thisStartTime).month() + 1
      const thisStartYear = this.$moment(this.thisStartTime).year()
      const thisEndMonth = this.$moment(this.thisEndTime).month() + 1
      const thisEndYear = this.$moment(this.thisEndTime).year()

      this.fetchGrossRevenue2years({
        lastStartMonth,
        lastStartYear,
        lastEndMonth,
        lastEndYear,
        hospitalCode: this.hospitalCode2,
        thisStartMonth,
        thisStartYear,
        thisEndMonth,
        thisEndYear
      }).catch(err => {
        console.error(err)
      })
    },

    submitFinancialOverview () {
      const defaultFinancialOverview = this.$cloneDeep(this.financialOverview)

      this.filteredFinancialOverview = defaultFinancialOverview.map(item => {

        item.data = item.data.filter(d => {
          if (this.filterCategory === 'All') return d
          else return d.category === this.filterCategory
        }).filter(d => {
          if (this.filterServiceType === 'All') return d
          else return d.serviceType === this.filterServiceType
        })

        return item
      })
    }
  }
}
</script>

<style lang="scss">
.vs-table--tbody-table {
  min-width: 0;
}
</style>
